<script>
export default {
  name: 'PreviousAddress',

  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      companyName: this.value.address.organisation_name,
      buildingName: this.value.address.premise,
      addressLine1: this.value.address.thoroughfare,
      addressLine2: this.value.address.dependent_locality,
      city: this.value.address.locality,
      county: this.value.address.subAdministrativeArea,
      country: this.value.address.administrative_area,
      postcode: this.value.address.postal_code,
      residencyStarted: new Date(this.value.start_date.split('/').reverse().join('/')).toLocaleDateString('en-GB'),
      residencyEnded: new Date(this.value.end_date.split('/').reverse().join('/')).toLocaleDateString('en-GB'),
    };
  },
};
</script>

<template>
  <div class="py-1">
    {{ this.value.start_date }}
    <div class="d-flex justify-content-end">
      <span class="text-green cursor-pointer mr-1 previous-action-button" @click="$emit('edit', index)">
        <i class="fa fa-edit"></i>
      </span>
      <span class="text-danger cursor-pointer mr-3 previous-action-button" @click="$emit('remove', index)">
        <i class="fas fa-trash-alt"></i>
      </span>
    </div>
    <b-list-group class="p-0">
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Company Name </label>
          <span class="col-md-9">{{ companyName }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Building Name or Number: </label>
          <span class="col-md-9">{{ buildingName }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Address Line 1:</label>
          <span class="col-md-9">{{ addressLine1 }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Address Line 2:</label>
          <span class="col-md-9">{{ addressLine2 }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Town/City: </label>
          <span class="col-md-9">{{ city }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> County: </label>
          <span class="col-md-9">{{ county }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Country: </label>
          <span class="col-md-9">{{ country }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Postcode: </label>
          <span class="col-md-9">{{ postcode }}</span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Residency Started </label>
          <span class="col-md-9">
            {{ residencyStarted }}
          </span>
        </h5>
      </b-list-group-item>
      <b-list-group-item class="p-0">
        <h5>
          <label class="col-md-3"> Residency Ended </label>
          <span class="col-md-9">
            {{ residencyEnded }}
          </span>
        </h5>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
