import axios from 'axios';

export default ({ name, phone, address, email }) => {
  const preparedData = {
    name,
    phone,
    address,
    email,
  };

  return axios.post(`${process.env.VUE_APP_API_ENDPOINT}/firms`, preparedData);
};
