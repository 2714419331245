import axios from 'axios';

export default ({ id, name, phone, address, email }) => {
  const preparedData = {
    name,
    phone,
    address,
    email,
  };

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/firms/${id}`, preparedData);
};
